.layout {
  width: 100%;
  min-height: 100vh;

  .header {
    text-align: left;
    height: 5vh;
    background-color: #ffffff;
    font-size: 24px;
    color: black;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .logo {
      font-size: 20px;
      color: black;
      font-weight: 800;
    }
  }

  .content {
    text-align: center;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;


    .title {
      height: 60vh;
      width: 100%;

      .title-content {
        position: relative;
        height: 60vh;
        /* background-color: aqua; */

        .title-h1 {
          font-size: 48px;
          color: black;
          font-weight: 800;
          /* top: 20vh; */
        }

        .title-text {
          position: absolute;
          top: 30%;
          left: 20%;
          font-size: 60px;
          color: white;
          font-weight: 800;
        }

        .title-line {
          position: absolute;
          top: 48%;
          left: 20%;
          font-size: 40px;
          color: white;
          font-weight: 800;
        }

        .banner {
          height: 60vh;
          width: 80%;
          margin: 0px auto;
         background-color: rgba(0, 0, 0, 0.5);
          /* 半透明黑色 */
          z-index: 999; 
          transition: opacity 0.3s ease;
          /* 平滑显示 */
          opacity: 0.8;
          /* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)); */
        }
      }

    }

    .home {
      height: 30vh;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      /* background-color: red; */
      .div {
        height: 20vh;
        width: 20%;
        margin: 0px auto;
        text-align: center;
        border-radius: 8px;
        padding: 10px;
        background-color: #f1f1f1;
      }
    }
  }

  .footer {
    /* text-align: center; */
    height: 5vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}